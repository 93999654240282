<template>
  <div class="aboutUs">
    <div class="aboutUs-banner">
      <h1>北京如玥星辰科技有限公司</h1>
      <p>Fourtune star tech</p>
    </div>
    <div class="aboutUs-introduce">
      <h2>公司简介</h2>
      <p>
        “如玥星辰”致力于医疗大数据，AI辅助医疗，移动应用，解决医疗领域供需矛<br />盾，缔造医疗健康新格局，助力医疗健康事业高质量发展。
      </p>
      <div class="aboutUs-introduce__title">
        <img src="@/assets/images/aboutUs-text.png" alt="" height="100%" />
      </div>
    </div>
    <div class="aboutUs-company">
      <div class="aboutUs-company__left"></div>
      <div class="aboutUs-company__right">
        <div class="aboutUs-company__right-item"></div>
        <div class="aboutUs-company__right-item"></div>
        <div class="aboutUs-company__right-item"></div>
      </div>
    </div>
    <foot></foot>
  </div>
</template>

<script>
export default {
  components: {
    foot: () => import("@/components/footer.vue"),
  },
};
</script>

<style lang="scss" scoped>
$content-width: 1200px;
.aboutUs {
  // margin-top: -64px;
  &-banner {
    height: 458px;
    background: url("../../assets/images/aboutUs-banner.png") no-repeat;
    background-size: cover;
    margin-bottom: 104px;
    h1 {
      font-weight: 600;
      color: #ffffff;
      font-size: 36px;
      text-align: center;
      padding-top: 220px;
      margin: 0;
    }
    p {
      font-size: 32px;
      font-weight: 500;
      color: rgba(255, 255, 255, 0.65);
      text-align: center;
      margin: 0;
    }
  }
  &-introduce {
    text-align: center;
    h2 {
      font-size: 36px;
      font-weight: 600;
      color: #333333;
      margin: 0;
    }
    p {
      font-size: 20px;
      font-weight: 400;
      color: #000000;
      margin: 40px 0 32px;
    }
    &__title {
      height: 40px;
      margin-bottom: 55px;
    }
  }
  &-company {
    width: $content-width;
    margin: 0 auto;
    margin-bottom: 90px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    height: 569px;
    &__left {
      width: 855px;
      background: #d9d9d9;
      background: #d9d9d9;
    }
    &__right {
      display: flex;
      flex-flow: column;
      justify-content: space-between;
      &-item {
        width: 321px;
        height: 174px;
        background: #d9d9d9;
      }
    }
  }
}
</style>